import { Tabs } from "antd"
import { StyleTabsCustom } from "./styled" //

const TabsCustom = ({ items, defaultActiveKey, type, onchange, activeKey }) => {
  return (
    <StyleTabsCustom>
      <Tabs
        // destroyInactiveTabPane={true}
        type={type}
        defaultActiveKey={defaultActiveKey}
        items={items}
        onChange={onchange ? onchange : null}
        activeKey={activeKey}
      />
    </StyleTabsCustom>
  )
}

export default TabsCustom

