import Cookies from "js-cookie"

const STORAGE = {
  TOKEN: "token-hr-sortware",
  USER_INFO: "user-info-hr-sortware",
  REMEMBER_LOGIN: "remember-login",
  KEY_MENU_ACTIVE: "key-active",
  PROFILE_SELECT: "profile-select",
  DOCUMENT_SELECT: "document-select",
  FOLDER_SELECT: "folder-select",
  EXP_TIME: "time-log",
}

export const getStorage = name => {
  const remember = localStorage.getItem(STORAGE.REMEMBER_LOGIN)
  let data
  if (remember) {
    data =
      typeof window !== "undefined" && name !== undefined
        ? localStorage.getItem(name)
        : ""
  } else {
    data =
      typeof window !== "undefined" && name !== undefined
        ? sessionStorage.getItem(name)
        : ""
  }
  try {
    if (data) return JSON.parse(data)
  } catch (err) {
    return data
  }
}

export const setStorage = (name, value) => {
  const remember = localStorage.getItem(STORAGE.REMEMBER_LOGIN)
  const stringify = typeof value !== "string" ? JSON.stringify(value) : value
  if (remember) {
    return localStorage.setItem(name, stringify)
  } else {
    return sessionStorage.setItem(name, stringify)
  }
}

export const deleteStorage = name => {
  const remember = localStorage.getItem(STORAGE.REMEMBER_LOGIN)
  if (remember) {
    return localStorage.removeItem(name)
  } else {
    return sessionStorage.removeItem(name)
  }
}

export const clearStorage = () => {
  const remember = localStorage.getItem(STORAGE.REMEMBER_LOGIN)
  if (remember) {
    return localStorage.clear()
  } else {
    return sessionStorage.clear()
  }
}


// Cookie

export const getStorageCookie = (name) => {
  const remember = Cookies.get(STORAGE.REMEMBER_LOGIN);
  let data;

  if (remember) {
    data = Cookies.get(name);
  } else {
    data = Cookies.get(name);
  }

  try {
    if (data) return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export const setStorageCookie = (name, value) => {
  const remember = Cookies.get(STORAGE.REMEMBER_LOGIN);
  const stringify = typeof value !== "string" ? JSON.stringify(value) : value;
  const options = remember ? { expires: 14 } : {};
  Cookies.set(name, stringify, options);
  return Cookies.get(name);
}
export const deleteStorageCookie = (name) => {
  const remember = Cookies.get(STORAGE.REMEMBER_LOGIN);

  if (remember) {
    Cookies.remove(name);
  } else {
    Cookies.remove(name);
  }
};

// export const clearStorageCookie = () => {
//   const cookies = Cookies.get();
//   for (let cookie in cookies) {
//     Cookies.remove(cookie);
//   }
// }

export const clearStorageCookie = () => {
  const remember = Cookies.get(STORAGE.REMEMBER_LOGIN);

  if (remember) {
    // Cookies.remove(STORAGE.REMEMBER_LOGIN); 
    Cookies.remove(STORAGE);
  }
  const cookies = Cookies.get();
  for (let cookie in cookies) {
    Cookies.remove(cookie);
  }
};





export default STORAGE

