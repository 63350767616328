import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listDepartment: [],
}

export const hrSlice = createSlice({
  name: "hr",
  initialState,
  reducers: {
    sliceDepartments: (state, action) => {
      state.listDepartment = action.payload
    },
  },
})

export const { sliceDepartments } = hrSlice.actions

export default hrSlice.reducer

